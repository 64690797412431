import React from 'react';
import styles from './Footer.module.css'; // Import CSS module for styling

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.about}>
        <h3>About us</h3>
        <p>Gomaterial is an online procurement e-commerce website specializing in construction and interior materials.
           As India’s first online procurement platform catering to both B2B and B2C needs,
           we offer a comprehensive range of products, including tiles, TMT bars, sanitary ware, steel,
           cement, and more, providing a one-stop solution for all your construction and interior material requirements.</p>
      </div>
      <div className={styles.services}>
        <h3>Our Services</h3>
        <ul>
          <li><a href="#">Steel</a></li>
          <li><a href="#">Tiles</a></li>
          <li><a href="#">TMT</a></li>
          <li><a href="#">Cements</a></li>
          <li><a href="#">Reviews</a></li>
          <li><a href="#">Careers</a></li>
          <li><a href="#">Pricing</a></li>
          <li><a href="#">Press inquiries</a></li>
        </ul>
      </div>
      <div className={styles.contact}>
        <h3>Contact</h3>
        <p>746B, 7th floor, JMD Megapolis Sector 48, Gurgaon, Haryana 122018</p>
        <p style={{padding: '0px 20px'}}>Email: contact@gomaterial.in</p>
      </div>
      <div className={styles.socialIcons}>
        <h3>Follow Us</h3>
        <a href="https://www.facebook.com/profile.php?id=61563156721774"><i className="fab fa-facebook-f"></i></a>
        <a href="https://www.instagram.com/gomaterial_2/"><i className="fab fa-instagram"></i></a>
        <a href="https://www.linkedin.com/in/gomaterial/"><i className="fab fa-linkedin-in"></i></a>
      </div>
    </footer>
  );
};

export default Footer;
