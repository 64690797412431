// RequirementForm.js

import React, { useState } from 'react';
import styles from './RequirementForm.module.css'; // Import CSS module for styling

const RequirementForm = () => {
  const [activeSection, setActiveSection] = useState('Buy');
  const [activeButton, setActiveButton] = useState('Buy');

  const handleButtonClick = (section) => {
    setActiveSection(section);
    setActiveButton(section);
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <button
          className={`${styles.button} ${activeButton === 'Buy' ? styles.activeButton : ''}`}
          onClick={() => handleButtonClick('Buy')}
        >
          Buy
        </button>
        <button
          className={`${styles.button} ${activeButton === 'Sell' ? styles.activeButton : ''}`}
          onClick={() => handleButtonClick('Sell')}
        >
          Sell
        </button>
      </div>
      <div className={styles.section} style={{ display: activeSection === 'Buy' ? 'block' : 'none' }}>
        <h2>Buy</h2>
        <div className={styles.inputs}>
          {/* Inputs for Buy section */}
          <input type="text" placeholder="Enter Product" />
          <input type="text" placeholder="Quantity" />
          <input type="text" placeholder="Company" />
          <input type="text" placeholder="Pincode" />
          <input type="email" placeholder="Email" />
          <input type="text" placeholder="Mobile Number" />
          <button className={styles.submitButton}>Submit</button>
        </div>
      </div>
      <div className={styles.section} style={{ display: activeSection === 'Sell' ? 'block' : 'none' }}>
        <h2>Sell</h2>
        <div className={styles.inputs}>
          {/* Inputs for Sell section */}
          <input type="text" placeholder="Enter Product" />
          <input type="text" placeholder="Quantity" />
          <input type="text" placeholder="Company" />
          <input type="text" placeholder="Pincode" />
          <input type="email" placeholder="Email" />
          <input type="text" placeholder="Mobile Number" />
          <button className={styles.submitButton}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default RequirementForm;
