import "./Profile.css";

const Profile = () => {
  return (
    <div className="profile">
      <div className="profile-child" />
    </div>
  );
};

export default Profile;
