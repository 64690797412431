import React, { useState, useEffect } from 'react';
import styles from './Main.module.css';
import factory from '../../../assets/poster.mp4';
import backgroundImage from '../../../assets/Structure.jpg'; // Import the background image
import { FaSearch } from 'react-icons/fa'; // Import icons as needed

const Main = () => {
  const placeholderText = "Search for raw material, home fittings and more...";
  const highlights = ["product,", "service,", "solution,"]; // List of highlights
  const [highlightIndex, setHighlightIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setHighlightIndex((prevIndex) => (prevIndex + 1) % highlights.length);
    }, 3000); // Change highlight every 3 seconds

    return () => clearInterval(intervalId);
  }, [highlights.length]);

  return (
    <div className={styles.mainb2b}>
      {/* Video for larger screens */}
      <video className={styles.backgroundVideoB2b} autoPlay loop muted>
        <source src={factory} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Image for mobile view */}
      <img src={backgroundImage} alt="Background" className={styles.backgroundImageB2b} />

      <div className={styles.containerB2b}>
        <div className={styles.titleB2b}>India's Largest Online Marketplace For</div>
        <div className={styles.titleB2b}>Construction material</div>
        <div className={styles.buttonsB2b}>
          <button className={`${styles.buttonB2b} ${styles.leftB2b}`}>Buy</button>
          <button className={`${styles.buttonB2b} ${styles.rightB2b}`}>Sell</button>
        </div>
      </div>
    </div>
  );
}

export default Main;
