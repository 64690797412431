import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Register.css";

const Register = () => {
  const navigate = useNavigate();

  const onRectangleClick = useCallback(() => {
    navigate("/signin");
  }, [navigate]);

  return (
    <div className="register">
      <div className="register-child" />
      <div className="register-inner">
        <div className="frame-item" onClick={onRectangleClick} />
      </div>
    </div>
  );
};

export default Register;
