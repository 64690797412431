import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FaShoppingCart, FaUser, FaUserPlus } from 'react-icons/fa';
import logo from '../.././../assets/logo.png';

import styles from "./Header.module.css";

const Header = () => {
  const navigate = useNavigate();

  const onProfileClick = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src={logo} alt="Logo" className={styles['logo-icon']} />
        </div>
        <div className={styles["search-bar"]}>
          <input type="text" placeholder="Search for raw materials, home fittings and more..." />
        </div>
        <div className={styles.actions}>
          <button className={styles["action-cart"]}>
            <FaShoppingCart /> Cart
          </button>
          <button className={styles["action-user"]} onClick={onProfileClick}>
            <FaUser /> Sign In
          </button>
          <button className={styles["action-seller"]}>
            <FaUserPlus /> Become a Seller
          </button>
        </div>
      </header>
      <nav className={styles.subheader}>
        <ul className={styles.categories}>
          <li className={styles.category}>
            <span>Concrete</span>
            <ul className={styles.subcategories}>
              <li>Cement</li>
              <li>Precast Concrete</li>
            </ul>
          </li>
          <li className={styles.category}>
            <span>Wood</span>
            <ul className={styles.subcategories}>
              <li>Lumber</li>
              <li>Plywood</li>
            </ul>
          </li>
          <li className={styles.category}>
            <span>Steel</span>
            <ul className={styles.subcategories}>
              <li>Rebar</li>
              <li>Structural Steel</li>
            </ul>
          </li>
          <li className={styles.category}>
            <span>Bricks</span>
            <ul className={styles.subcategories}>
              <li>Clay Bricks</li>
              <li>Concrete Bricks</li>
            </ul>
          </li>
          <li className={styles.category}>
            <span>Roofing</span>
            <ul className={styles.subcategories}>
              <li>Shingles</li>
              <li>Metal Roofing</li>
            </ul>
          </li>
          <li className={styles.category}>
            <span>Flooring</span>
            <ul className={styles.subcategories}>
              <li>Tiles</li>
              <li>Wood Flooring</li>
            </ul>
          </li>
          <li className={styles.category}>
            <span>Plumbing</span>
            <ul className={styles.subcategories}>
              <li>Pipes</li>
              <li>Fittings</li>
            </ul>
          </li>
          <li className={styles.category}>
            <span>Electrical</span>
            <ul className={styles.subcategories}>
              <li>Wires</li>
              <li>Switches</li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
