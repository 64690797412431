import "./Signin.css";

const Signin = () => {
  return (
    <div className="signin">
      <div className="haemodialysis-login" />
    </div>
  );
};

export default Signin;
