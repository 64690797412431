// Deals.js
import React from 'react';
import styles from './Deals.module.css'; // Import CSS module for DealsContainer

// Import all images
import ledLightsImage from '../../../assets/lights.png';
import cementImage from '../../../assets/cement-1.png';
import sanitarywareImage from '../../../assets/bathroom-sanitry.png';

const Deals = () => {
  return (
    <div>
    <div className={styles['container-title']}>
    <h2 className={styles['title']}>Great deals, selling fast!</h2>
    </div>
    <div className={styles['deals-container']}>
      <div className={styles.deal}>
        <img src={ledLightsImage} alt="LED lights" className={styles['deal-image']} />
        <div className={styles['deal-content']}>
          <h3>LED lights</h3>
          <p>Get 20% off</p>
        </div>
      </div>
      <div className={styles.deal}>
        <img src={cementImage} alt="Cement" className={styles['deal-image']} />
        <div className={styles['deal-content']}>
          <h3>Cement</h3>
          <p>Get up to 40% off on bulk orders</p>
        </div>
      </div>
      <div className={styles.deal}>
        <img src={sanitarywareImage} alt="Sanitaryware & fittings" className={styles['deal-image']} />
        <div className={styles['deal-content']}>
          <h3>Sanitaryware & fittings</h3>
          <p>Check them now</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Deals;
