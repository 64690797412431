import "./View.css";

const View = () => {
  return (
    <div className="view">
      <div className="main-content-area" />
    </div>
  );
};

export default View;
