import { useCallback } from "react";
import Header from "../components/B2B/Header/Header";
import MainB2b from "../components/B2B/Main/Main"; // Adjust the import path accordingly
import Switch from "../components/B2B/Switch/Switch"; // Adjust the import path accordingly
import ShopWithUs from "../components/B2B/Shop/ShopWithUs"; // Adjust the import path accordingly
import ServiceBox from "../components/B2B/ServiceBox/ServiceBox"; // Adjust the import path accordingly
import RequirementForm from "../components/B2B/RequirementForm/RequirementForm"; // Adjust the import path accordingly
import AboutContainer from "../components/B2B/AboutContainer/AboutContainer"; // Adjust the import path accordingly
import Footer from "../components/B2B/Footer/Footer"; // Adjust the import path accordingly

import Modal from "../components/B2B/Modal/Modal"; // Adjust the import path accordingly

import { useNavigate } from "react-router-dom";
import "./Business.css";

const Business = () => {
  const navigate = useNavigate();

  const onRectangleClick = useCallback(() => {
    navigate("/view");
  }, [navigate]);

  return (
    <div className="home">
      <Header />
      <MainB2b />
      <Switch />
      <ShopWithUs />
      <ServiceBox />
      <RequirementForm />
      <AboutContainer />
      <Footer />
      <Modal />
      <div className="home-inner">
        <div className="frame-child" onClick={onRectangleClick} />
      </div>
    </div>
  );
};

export default Business;
