import React, { useState, useEffect } from 'react';
import './Main.css';
import factory from '../../../assets/factory.mov';
import structureImage from '../../../assets/Structure.jpg'; // Import the image
import { FaSearch } from 'react-icons/fa'; // Import icons as needed

const Main = () => {
  const placeholderText = "Search";
  const highlights = ["product,", "service,", "solution,"]; // List of highlights
  const [highlightIndex, setHighlightIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setHighlightIndex((prevIndex) => (prevIndex + 1) % highlights.length);
    }, 3000); // Change highlight every 3 seconds

    return () => clearInterval(intervalId);
  }, [highlights.length]);

  return (
    <div className="main">
      {/* Video for larger screens */}
      <video className="background-video" autoPlay loop muted>
        <source src={factory} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Image for mobile view */}
      <img src={structureImage} alt="Structure" className="background-image" />

      <div className="motto">
        <h1>Find the right <span className="highlight">{highlights[highlightIndex]}</span> right away</h1>
        <div className="search-bar">
          <input type="text" id="searchInput" placeholder={placeholderText} />
          <button><FaSearch /></button>
        </div>
        <div className="keywords">
          <span className="keyword">Popular:</span>
          <span className="keyword">Raw material</span>
          <span className="keyword">TMT</span>
          <span className="keyword">Bathroom fittings</span>
          <span className="keyword">PVC pipes</span>
        </div>
      </div>

      <div className="w-96 bg-white shadow rounded"></div>

      <div className="signup-box">
        <h2>Sign up with us as a seller</h2>
        <p>Joining is quick and easy! Start selling your products in just a few simple steps.</p>
        <input type="text" placeholder="Name" />
        <input type="number" placeholder="Phone" />
        <input type="text" placeholder="Place" />
        <button>Sign up</button>
      </div>
    </div>
  );
}

export default Main;
