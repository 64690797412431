import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Switch.module.css'; // Import CSS module for Switch

class Switch extends React.Component {
  handleIndividualClick = () => {
    const { navigate } = this.props;
    navigate('/'); // Navigate to the home page or any other route for individual buyers
  };

  handleBusinessClick = () => {
    const { navigate } = this.props;
    navigate('/Business'); // Navigate to the B2B page
  };

  render() {
    return (
      <div className={styles['switch-container']}>
        <button className={styles['individual-buyer']} onClick={this.handleIndividualClick}>
          Individual buyer
        </button>
        <button className={styles.business} onClick={this.handleBusinessClick}>
          Business
        </button>
      </div>
    );
  }
}

// Wrap Switch component to inject navigation props
const withNavigate = (Component) => (props) => {
  const navigate = useNavigate();
  return <Component {...props} navigate={navigate} />;
};

export default withNavigate(Switch);
