import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Highlight.module.css'; // Import the CSS file
import product1 from '../../../assets/tiles.jpg'; // Import your images
import product2 from '../../../assets/bathroom.jpeg';
import product3 from '../../../assets/electricity.jpeg';
import product4 from '../../../assets/lights.png';
import product5 from '../../../assets/sanitry.png';

const Highlight = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000, // Slide transition speed in milliseconds
    autoplay: true, // Enable autoplay
    autoplaySpeed: 4000, // Autoplay speed in milliseconds
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div>
      <div className={styles.trendingTitle}>
        <h2>Trending Products</h2>
      </div>
      <Slider className={styles['highlight-slider']} {...settings}>
        <div>
          <img src={product1} alt="Product 1" />
          <h3>Tiles/Granite</h3>
          <p>Premium Italian Marble</p>
        </div>
        <div>
          <img src={product2} alt="Product 2" />
          <h3>Bathroom Fittings</h3>
          <p>High-quality fittings for your bathroom</p>
        </div>
        <div>
          <img src={product3} alt="Product 3" />
          <h3>Electrical Appliances</h3>
          <p>Energy-efficient electrical appliances</p>
        </div>
        <div>
          <img src={product4} alt="Product 4" />
          <h3>Lights</h3>
          <p>Modern and stylish lighting solutions</p>
        </div>
        <div>
          <img src={product5} alt="Product 5" />
          <h3>Sanitary Ware</h3>
          <p>High-quality sanitary ware for your home</p>
        </div>
        {/* Add more products as needed */}
      </Slider>
    </div>
  );
};


export default Highlight;
