// CategoryCollage.js
import React from 'react';
import styles from './CategoryCollage.module.css'; // Import CSS module for CategoryCollage

// Import all images
import structureMaterialImage from '../../../assets/Structure.jpg';
import flooringAndTilesImage from '../../../assets/tiles-1.jpg';
import bathroomAndSanitaryImage from '../../../assets/bathroom-sanitry-1.jpg';
import electricalAndLightingsImage from '../../../assets/electricity.jpeg';
import hardwareAndFittingsImage from '../../../assets/hardware.jpeg';
import plywoodAndBoardsImage from '../../../assets/plywood-board-1.jpg';
import modularKitchen from '../../../assets/modular-kitchen-2.jpg';
import steel from '../../../assets/steel.jpg';

const CategoryCollage = () => {
  return (
    <div>
    <div className={styles['container-title']}>
    <h2 className={styles['title']}>Shop by categories</h2>
    </div>
    <div className={styles['collage-container']}>
      <div className={styles.category}>
        <img src={structureMaterialImage} alt="Structure Material" className={styles['category-image']} />
        <div className={styles['category-label']}>Structure Material</div>
      </div>
      <div className={styles.category}>
        <img src={flooringAndTilesImage} alt="Flooring and Tiles" className={styles['category-image']} />
        <div className={styles['category-label']}>Flooring and Tiles</div>
      </div>
      <div className={styles.category}>
        <img src={bathroomAndSanitaryImage} alt="Bathroom and Sanitary" className={styles['category-image']} />
        <div className={styles['category-label']}>Bathroom and Sanitary</div>
      </div>
      <div className={styles.category}>
        <img src={electricalAndLightingsImage} alt="Electrical and Lightings" className={styles['category-image']} />
        <div className={styles['category-label']}>Electrical and Lightings</div>
      </div>
      <div className={styles.category}>
        <img src={hardwareAndFittingsImage} alt="Hardware and Fittings" className={styles['category-image']} />
        <div className={styles['category-label']}>Hardware and Fittings</div>
      </div>
      <div className={styles.category}>
        <img src={plywoodAndBoardsImage} alt="Plywood and Boards" className={styles['category-image']} />
        <div className={styles['category-label']}>Plywood and Boards</div>
      </div>
      <div className={styles.category}>
        <img src={modularKitchen} alt="Modular Kitchen" className={styles['category-image']} />
        <div className={styles['category-label']}>Modular Kitchen</div>
      </div>
      <div className={styles.category}>
        <img src={steel} alt="Steel" className={styles['category-image']} />
        <div className={styles['category-label']}>Steel</div>
      </div>
      {/* Add more categories as needed */}
    </div>
    </div>
  );
};

export default CategoryCollage;
