import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './BrandSlider.module.css';

import brand1Image from '../../../assets/bosch.png';
import brand2Image from '../../../assets/asianpaints.png';
import brand3Image from '../../../assets/jaguar.png';
import brand4Image from '../../../assets/kajaria.png';
import brand5Image from '../../../assets/havells.png';
import brand6Image from '../../../assets/bosch.png';
import brand7Image from '../../../assets/bosch.png';
import brand8Image from '../../../assets/bosch.png';

const brands = [
  { name: 'Brand 1', image: brand1Image },
  { name: 'Brand 2', image: brand2Image },
  { name: 'Brand 3', image: brand3Image },
  { name: 'Brand 4', image: brand4Image },
  { name: 'Brand 5', image: brand5Image },
  { name: 'Brand 6', image: brand6Image },
  { name: 'Brand 7', image: brand7Image },
  { name: 'Brand 8', image: brand8Image }
];

const BrandSlider = () => {
  const [isPaused, setIsPaused] = useState(false);

  const settings = {
    dots: false,
    infinite: true, // Enable infinite sliding
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: !isPaused,
    autoplaySpeed: 2000,
    pauseOnHover: true
  };

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  return (
    <div className={styles['brand-slider-container']} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <h2 className={styles['brand-slider-title']}>Shop By Brands</h2>
      <Slider {...settings}>
        {brands.map((brand, index) => (
          <div key={index}>
            <img src={brand.image} alt={brand.name} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BrandSlider;
