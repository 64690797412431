import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding, faCreditCard, faPiggyBank, faHeadset, faShieldAlt, faTruck, faTag, faCog, faClock } from '@fortawesome/free-solid-svg-icons';
import styles from './ServiceBox.module.css'; // Import CSS module

const ServiceBox = () => {
  const [activeTab, setActiveTab] = useState('Buyers');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className={styles.serviceBox}>
      <div className={styles.switchContainer}>
        <button className={activeTab === 'Buyers' ? `${styles.switchButton} ${styles.active}` : styles.switchButton} onClick={() => handleTabClick('Buyers')}>Buyers</button>
        <button className={activeTab === 'Suppliers' ? `${styles.switchButton} ${styles.active}` : styles.switchButton} onClick={() => handleTabClick('Suppliers')}>Suppliers</button>
      </div>
      <div className={styles.cardsContainer}>
        {activeTab === 'Buyers' ? (
          <>
            <div className={styles.card}>
              <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faCreditCard} className={styles.icon} />
              </div>
              <h3 className={styles.cardSubtitle}>Online Payment</h3>
              <div>100% secure </div>
            </div>
            <div className={styles.card}>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon icon={faPiggyBank} className={styles.icon} />
              </div>
              <h3 className={styles.cardSubtitle}>Big Savings</h3>
              <div>Save more with us</div>
            </div>
            <div className={styles.card}>
              <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faHeadset} className={styles.icon} />
              </div>
              <h3 className={styles.cardSubtitle}>Best online support</h3>
              <div>Hours: 12*7</div>
            </div>
            <div className={styles.card}>
              <div className={styles.iconContainer}>
                <FontAwesomeIcon icon={faShieldAlt} className={styles.icon} />
              </div>
              <h3 className={styles.cardSubtitle}>100% Guarantee</h3>
              <div>Original products </div>
            </div>
            {/* Add more buyer cards as needed */}
          </>
        ) : (
<>
  <div className={styles.card}>
    <div className={styles.iconContainer}>
      <FontAwesomeIcon icon={faTruck} className={styles.icon} />
    </div>
    <h3 className={styles.cardSubtitle}>Large Supplies</h3>
    <div>Bulk quantities, high quality.</div>
  </div>
  <div className={styles.card}>
    <div className={styles.iconContainer}>
      <FontAwesomeIcon icon={faTag} className={styles.icon} />
    </div>
    <h3 className={styles.cardSubtitle}>Best Rates</h3>
    <div>Competitive without compromise.</div>
  </div>
  <div className={styles.card}>
    <div className={styles.iconContainer}>
      <FontAwesomeIcon icon={faCog} className={styles.icon} />
    </div>
    <h3 className={styles.cardSubtitle}>Easy Management</h3>
    <div>Simplified procurement tools.</div>
  </div>
  <div className={styles.card}>
    <div className={styles.iconContainer}>
      <FontAwesomeIcon icon={faClock} className={styles.icon} />
    </div>
    <h3 className={styles.cardSubtitle}>Fast Payments</h3>
    <div>Quick and secure.</div>
  </div>
  {/* Add more supplier cards as needed */}
</>


        )}
      </div>
    </div>
  );
};

export default ServiceBox;
