import React from 'react';
import styles from './ShopWithUs.module.css'; // Import CSS module for ShopWithUs
import polymer from '../../../assets/polymer.png';
import nonferous from '../../../assets/non-ferous.png';
import bricsImage from '../../../assets/Brics.png'; // Import images for products
import fittingsImage from '../../../assets/fittings.png';
import lumberImage from '../../../assets/lumber.png';
import metalRoofImage from '../../../assets/metal-roof.png';
import rebarImage from '../../../assets/rebar.png';
import tilesImage from '../../../assets/tiles.png';
import wiresImage from '../../../assets/wires.png';
import woodenFloorImage from '../../../assets/wooden-floor.png';

// Define an array of product categories with image names
const categories = [
  'Polymer',
  'Non Ferous',
  'Brics',
  'Fittings',
  'Lumber',
  'Metal Roof',
  'Rebar',
  'Tiles',
  'Wires',
  'Wooden Floor'
];

// Define an array of products in order with updated names
const products = [
  { name: 'Polymer', image: polymer },
  { name: 'Non Ferous', image: nonferous },
  { name: 'Brics', image: bricsImage },
  { name: 'Fittings', image: fittingsImage },
  { name: 'Lumber', image: lumberImage },
  { name: 'Metal Roof', image: metalRoofImage },
  { name: 'Rebar', image: rebarImage },
  { name: 'Tiles', image: tilesImage },
  { name: 'Wires', image: wiresImage },
  { name: 'Wooden Floor', image: woodenFloorImage }
];

const ShopWithUs = () => {
  return (
    <div className={styles['shop-container']}>
      <h2 className={styles['shop-title']}>Shop with us</h2>
      <div className={styles['product-navigation']}>
        {categories.map(category => (
          <button key={category} className={styles['nav-button']}>{category}</button>
        ))}
      </div>
      <div className={styles['product-grid']}>
        {products.map(product => (
          <div key={product.name} className={styles['product-card']}>
            <img src={product.image} alt={product.name} className={styles['product-image']} />
            <div className={styles['product-details']}>
              <h3 className={styles['product-name']}>{product.name}</h3>
              <span className={styles['product-arrow']}>&rarr;</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopWithUs;
