import React from 'react';
import styles from './ShopWithUs.module.css'; // Import CSS module for ShopWithUs
import basinMixerImage from '../../../assets/basin.png'; // Import images for products
import bathTubImage from '../../../assets/plywood.jpeg';
import stopClockImage from '../../../assets/clock.png';
import tilesImage from '../../../assets/tiles.jpg';
import thermostatControlImage from '../../../assets/hardware.jpeg';
import healthFaucetImage from '../../../assets/electricity.jpeg';
import showerSetImage from '../../../assets/bathroom.jpeg';
import chimney from '../../../assets/chimny.png';

// Define an array of product categories
const categories = [
  'All',
  'Faucets & showers',
  'Kitchen',
  'Plumbing & Sanitary',
  'Wall And Floor Tiles',
  'TMT',
  'Steel',
  'Electrical'
];

// Define an array of products in order
const products = [
  { name: 'Basin', image: basinMixerImage },
  { name: 'Modular Kitchen', image: bathTubImage },
  { name: 'Clocks', image: stopClockImage },
  { name: 'Tiles', image: tilesImage },
  { name: 'Thermostatic Control', image: thermostatControlImage },
  { name: 'Lights and Lamps', image: healthFaucetImage },
  { name: 'Shower Set', image: showerSetImage },
  { name: 'Chimney', image: chimney }
];

const ShopWithUs = () => {
  return (
    <div className={styles['shop-container']}>
      <h2 className={styles['shop-title']}>Shop with us</h2>
      <div className={styles['product-navigation']}>
        {categories.map(category => (
          <button key={category} className={styles['nav-button']}>{category}</button>
        ))}
      </div>
      <div className={styles['product-grid']}>
        {products.map(product => (
          <div key={product.name} className={styles['product-card']}>
            <img src={product.image} alt={product.name} className={styles['product-image']} />
            <div className={styles['product-details']}>
              <h3 className={styles['product-name']}>{product.name}</h3>
              <span className={styles['product-arrow']}>&rarr;</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopWithUs;
