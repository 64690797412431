import React, { useState, useEffect } from 'react';
import styles from './Modal.module.css';

const Modal = () => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) { // Change 200 to the desired scroll distance
        setShowModal(true);
        window.removeEventListener('scroll', handleScroll); // Remove scroll listener after showing modal
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll); // Clean up scroll listener on component unmount
    };
  }, []);

  return (
    <div className={styles.app}>
      {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={handleCloseModal}>Close</button>
            <h2>Want to buy or sell material from us?</h2>
            <p>Fill the form our team will reach you soon.</p>
            <form>
              <label>
                Name:
                <input type="text" name="name" />
              </label>
              <label>
                Email:
                <input type="email" name="email" />
              </label>
              <label>
                Phone:
                <input type="number" name="phone" />
              </label>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
