import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FaShoppingCart, FaUser, FaUserPlus } from 'react-icons/fa'; // Import icons as needed
import logo from '../../../assets/logo.png';

import styles from "./Header.module.css";


const Header = () => {
  const navigate = useNavigate();

  const onProfileClick = useCallback(() => {
    navigate("/profile");
  }, [navigate]);


  const onLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <img src={logo} alt="Logo" className={styles['logo-icon']} onClick={onLogoClick} />

      </div>
      <div className={styles["search-bar"]}>
        {/* Add your search bar component here */}
        <input type="text" placeholder="Search for raw materials, home fittings and more..." />
      </div>
      <div className={styles.actions}>
        <button className={styles["action-cart"]}>
          <FaShoppingCart /> Cart
        </button>
        <button className={styles["action-user"]} onClick={onProfileClick}>
          <FaUser /> Sign In
        </button>
        <button className={styles["action-seller"]}>
          <FaUserPlus /> Become a Seller
        </button>
      </div>
    </header>
  );
  
};

export default Header;
