import { useCallback } from "react";
import Header from "../components/B2C/Header/Header";
import Main from "../components/B2C/Main/Main"; // Adjust the import path accordingly
import Switch from "../components/B2C/Switch/Switch"; // Adjust the import path accordingly
import CategoryCollage from "../components/B2C/Category-Container/CategoryCollage"; // Adjust the import path accordingly
import Deals from "../components/B2C/Deals/Deals"; // Adjust the import path accordingly
import ShopWithUs from "../components/B2C/Shop/ShopWithUs"; // Adjust the import path accordingly
import Highlight from "../components/B2C/Highlight/Highlight"; // Adjust the import path accordingly
import BrandSlider from "../components/B2C/Brands/BrandSlider"; // Adjust the import path accordingly
import ServiceBox from "../components/B2C/ServiceBox/ServiceBox"; // Adjust the import path accordingly
import RequirementForm from "../components/B2C/RequirementForm/RequirementForm"; // Adjust the import path accordingly
import AboutContainer from "../components/B2C/AboutContainer/AboutContainer"; // Adjust the import path accordingly
import Footer from "../components/B2C/Footer/Footer"; // Adjust the import path accordingly

import Modal from "../components/B2C/Modal/Modal"; // Adjust the import path accordingly

import { useNavigate } from "react-router-dom";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();

  const onRectangleClick = useCallback(() => {
    navigate("/view");
  }, [navigate]);

  return (
    <div className="home">
      <Header />
      <Main />
      <Switch />
      <CategoryCollage />
      <Deals />
      <ShopWithUs />
      <Highlight />
      <BrandSlider />
      <ServiceBox />
      <RequirementForm />
      <AboutContainer />
      <Footer />
      <Modal />
      <div className="home-inner">
        <div className="frame-child" onClick={onRectangleClick} />
      </div>
    </div>
  );
};

export default Home;
